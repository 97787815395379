import { writable } from 'svelte/store';

// Export the store so we can use it in any component
export const numberPromptDialog = writable(null);

export async function promptNumber(message, title = '', mods = {}) {
	let resolve;

	const promise = new Promise((_resolve) => {
		resolve = _resolve;
	});

	/**
	 * Possible Mods:
     * - label
     * - placeholder
     * - type (integer || decimal) - default: integer
     * - min
     * - max
     * - step
     * - value
	 */

	numberPromptDialog.set({
		message,
		title,
		mods,
		resolve,
	});

	return promise;
}
